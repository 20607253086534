<template>
  <section class="project-wrapper">
    <router-link :to="{ name: 'projectDetails', params: { slug: item.slug } }">
      <b-row>
        <b-col cols="12" lg="6">
          <h5 v-text="item.name" class="sub-heading text-sm-center" />
          <!-- <p v-text="item.description" class="sub-text text-sm-center" /> -->
          <read-more
            :link="`/store/${item.slug}`"
            class="sub-text text-sm-center"
            :more-str="$t('label.more')"
            :less-str="$t('label.less')"
            :text="item.description ? item.description : ''"
            :max-chars="200"
          ></read-more>
        </b-col>
        <b-col cols="12" lg="6">
          <div class="projects__tile" :class="{ 'projects__tile--lg': isLg }">
            <b-img
              fluid
              :src="item.main_media && item.main_media.path"
              class="projects__tile__img"
            />
          </div>
        </b-col>
      </b-row>
    </router-link>
  </section>
</template>

<script>
export default {
  name: "ProjectTile",

  props: {
    item: {
      type: Object,
      required: true
    },
    isLg: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    description() {
      if (this.item.short_description) {
        return this.item.short_description;
      } else if (this.item.description) {
        return this.item.description;
      } else {
        return "";
      }
    }
  }
};
</script>
